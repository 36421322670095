import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, makeStyles } from '@material-ui/styles';
import { BnclCampaignsDto, CampaignCategoryHumanizedNames, VendorCampaignStatus } from 'apis/bnclCampaign';
import { requestVendors } from 'application/config/vendorsStore';
import { BnclListFiltersType, fetchBnclCampaigns, setBnclFilters } from 'application/vouchers/bnclSlice';
import { CustomTableCell } from 'components/common/CustomTable/CustomTableCell';
import ClearableTextField from 'components/common/Form/ClearableTextField';
import { Loader } from 'components/common/Loader/Loader';
import usePaginationFilter from 'components/hooks/usePaginationFilter';
import { format as formatDate, isPast } from 'date-fns';
import { isLoading, isNotRequested } from 'helpers/requestStateHelper';
import { Vendor } from 'models/vendor';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { useDispatch, useSelector } from 'store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: { margin: '44.5px' },
        header: {
            display: 'flex',
            flexDirection: 'row',
        },
        topActionBar: {
            height: 'fit-content',
            marginLeft: 'auto',
            marginRight: 0,
            alignSelf: 'center',
        },
        addButton: {
            marginLeft: theme.spacing(1),
        },
        headerTitle: {
            fontSize: '2rem',
        },
        tableFilters: {
            marginBottom: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
            '&> *': {
                marginLeft: theme.spacing(1),
            },
            '& .MuiTextField-root': {
                width: 260,
            },
        },
        categorySelect: {
            width: 150,
        },
        pagination: {
            height: 44,
            marginLeft: 'auto',
        },
        vendorLink: {
            display: 'inline-block',
            margin: `0 ${theme.spacing(1)}px`,
            '&:after': {
                content: '"/"',
                position: 'absolute',
                paddingLeft: theme.spacing(0.5),
                color: theme.palette.grey[500],
            },
            '&:last-child:after': {
                content: '""',
            },
        },
        tableRow: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.selected,
            },
        },
        status_expired: {
            color: theme.palette.grey[500],
        },
        status_draft: {
            color: theme.palette.warning.dark,
        },
        status_published: {
            color: theme.palette.success.dark,
        },
        status_unpublished: {
            color: theme.palette.error.dark,
        },
    }),
);

const checkboxFilters = [
    ['Published', 'showPublished'],
    ['Draft', 'showDraft'],
    ['Unpublished', 'showUnpublished'],
    ['Expired', 'showExpired'],
] as const;

const statusWeight = {
    [VendorCampaignStatus.PUBLISHED]: 4,
    [VendorCampaignStatus.DRAFT]: 3,
    [VendorCampaignStatus.UNPUBLISHED]: 2,
    expired: 1,
};

const getCampaignOrderWeight = (campaign: BnclCampaignsDto, filter: BnclListFiltersType) => {
    if (filter.showExpired && isPast(new Date(campaign.endDate))) {
        return statusWeight.expired;
    }
    if (filter.showPublished && campaign.vendorCampaignList.some(vc => vc.status === VendorCampaignStatus.PUBLISHED)) {
        return statusWeight.published;
    }
    if (filter.showDraft && campaign.vendorCampaignList.some(vc => vc.status === VendorCampaignStatus.DRAFT)) {
        return statusWeight.draft;
    }
    if (
        filter.showUnpublished &&
        campaign.vendorCampaignList.every(vc => vc.status === VendorCampaignStatus.UNPUBLISHED)
    ) {
        return statusWeight.unpublished;
    }

    return 0;
};

export default function BnclCampaigns(): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const vendors: { data: Vendor[]; requestState: string } = useSelector(state => state.apiConfig.vendors);
    const campaigns = useSelector(state => state.vouchers.bncl.list);
    const campaignFilters = useSelector(state => state.vouchers.bncl.listFilters);

    const orderedCampaigns = useMemo(() => {
        return [...campaigns.payload].sort(
            (a, b) => getCampaignOrderWeight(b, campaignFilters) - getCampaignOrderWeight(a, campaignFilters),
        );
    }, [campaigns.payload, campaignFilters]);

    const [pagination, setPagination] = usePaginationFilter(
        orderedCampaigns,
        campaignFilters,
        (campaign, filter) => {
            const searchMatch = filter.name ? campaign.name.toLowerCase().includes(filter.name.toLowerCase()) : true;
            const categoryMatch = filter.category ? campaign.category === filter.category : true;
            let statusMatch = filter.showPublished && filter.showDraft && filter.showUnpublished;

            if (filter.showPublished && filter.showDraft) {
                campaign.vendorCampaignList.some(vc =>
                    [VendorCampaignStatus.PUBLISHED, VendorCampaignStatus.DRAFT].includes(vc.status),
                ) && (statusMatch = true);
            } else if (filter.showPublished) {
                campaign.vendorCampaignList.some(vc => vc.status === VendorCampaignStatus.PUBLISHED) &&
                    (statusMatch = true);
            } else if (filter.showDraft) {
                campaign.vendorCampaignList.some(vc => vc.status === VendorCampaignStatus.DRAFT) &&
                    (statusMatch = true);
            }
            if (filter.showUnpublished) {
                campaign.vendorCampaignList.every(vc => vc.status === VendorCampaignStatus.UNPUBLISHED) &&
                    (statusMatch = true);
            }
            isPast(new Date(campaign.endDate)) && (statusMatch = filter.showExpired);

            return searchMatch && categoryMatch && statusMatch;
        },
        25,
    );

    const setSearchFilter = (filters: BnclListFiltersType) => dispatch(setBnclFilters(filters));

    const onAddCampaign = () => history.push(routes.newBnclCampaign);

    useEffect(() => {
        dispatch(fetchBnclCampaigns());

        if (isNotRequested(vendors)) {
            dispatch(requestVendors());
        }
    }, []);

    if (isLoading(vendors)) {
        return <Loader />;
    }

    return (
        <Box className={classes.wrapper}>
            {isLoading(campaigns) && <Loader />}
            <Box className={classes.header}>
                <Typography variant="subtitle1" component="h1" className={classes.headerTitle}>
                    BNCL Campaigns
                </Typography>
                <Box className={classes.topActionBar}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.addButton}
                        data-cy="Bncl-AddCampaignButton"
                        onClick={onAddCampaign}
                    >
                        Add Campaign
                    </Button>
                </Box>
            </Box>

            <Box className={classes.tableFilters}>
                <ClearableTextField
                    value={campaignFilters.name}
                    onChange={val => setSearchFilter({ ...campaignFilters, name: val })}
                    placeholder="filter campaign name"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    data-cy="BnclCampaignsFilterInput"
                    clearIconProps={{
                        'data-cy': 'BnclCampaignsFilterInputReset',
                    }}
                />
                <FormControl className={classes.categorySelect}>
                    <InputLabel shrink={true}>Category</InputLabel>
                    <Select
                        name="category"
                        value={campaignFilters.category}
                        onChange={ev => setSearchFilter({ ...campaignFilters, category: ev.target.value as string })}
                        displayEmpty
                        data-cy={'BnclCampaignsFilterCategory'}
                    >
                        <MenuItem value="">
                            <em>Any</em>
                        </MenuItem>
                        {Object.entries(CampaignCategoryHumanizedNames).map(([val, alias]) => (
                            <MenuItem key={val} value={val}>
                                {alias}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {checkboxFilters.map(([label, filterKey]) => (
                    <FormControlLabel
                        key={filterKey}
                        control={
                            <Checkbox
                                name={label}
                                color="primary"
                                checked={campaignFilters[filterKey]}
                                onChange={(ev, checked) =>
                                    setSearchFilter({ ...campaignFilters, [filterKey]: checked })
                                }
                                data-cy={`BnclCampaignsFilterStatus-${filterKey}`}
                            />
                        }
                        label={label}
                    />
                ))}
                <TablePagination
                    component="div"
                    className={classes.pagination}
                    count={pagination.count}
                    page={pagination.page}
                    onPageChange={(_, newPage) => setPagination.setPage(newPage)}
                    rowsPerPage={pagination.perPage}
                    rowsPerPageOptions={[]}
                />
            </Box>

            <TableContainer component={Paper} data-cy="Bncl-CampaignsTable">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>Campaign name</CustomTableCell>
                            <CustomTableCell width="110">Category</CustomTableCell>
                            <CustomTableCell width="130">End Date</CustomTableCell>
                            <CustomTableCell>Vendors</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pagination.result.map(row => {
                            const vendorsToShow = vendors.data
                                .map(vendor => {
                                    const match = row.vendorCampaignList.find(v => v.name === vendor.name);
                                    return {
                                        ...vendor,
                                        status: match?.status,
                                    };
                                })
                                .filter(vendor => !!vendor.status);

                            const campaignLink = routes.editBnclCampaign.replace(':campaignId', row.campaignId);
                            const expiredClass = isPast(new Date(row.endDate)) ? classes.status_expired : '';

                            return (
                                <TableRow key={row.campaignId} className={classes.tableRow} data-cy="Bncl-CampaignRow">
                                    <TableCell>
                                        <Link
                                            component={RouterLink}
                                            to={campaignLink}
                                            variant="body2"
                                            color="primary"
                                            className={`${classes.vendorLink} ${expiredClass}`}
                                            data-cy="Bncl-CampaignEditLink"
                                        >
                                            {row.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell className={expiredClass}>
                                        {CampaignCategoryHumanizedNames[row.category]}
                                    </TableCell>
                                    <TableCell className={expiredClass}>
                                        {formatDate(new Date(row.endDate), 'yyyy-LL-dd')}
                                    </TableCell>
                                    <TableCell>
                                        {vendorsToShow.map(vendor => (
                                            <Link
                                                component={RouterLink}
                                                key={vendor.name}
                                                to={campaignLink + `?tab=${vendor.name}`}
                                                variant="body2"
                                                color="primary"
                                                className={[
                                                    classes.vendorLink,
                                                    expiredClass || classes['status_' + vendor.status],
                                                ].join(' ')}
                                                data-cy="Bncl-CampaignEditLink"
                                            >
                                                {vendor.country}
                                            </Link>
                                        ))}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
